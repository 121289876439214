.profileModal {
  padding-top: 120px;
}

.marginWithBorder {
  margin-bottom: 10px;
  margin-top: 10px;
}

.detail-head {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0px;
}

.font14 {
  font-size: 14px;
}

.modal-body {
  animation: 1s slidein-left;
}

@keyframes slidein-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slidein-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* .cnfrmBtn {
  line-height: 0.428571 !important;
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
  height: 25px;
  background: black !important;
  border-radius: 0px !important;
} */

/* .close {
  border: none !important;
} */

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

/* .helpModalBtn {
  background: black !important;
  border-radius: 0px !important;
} */

.helpVideoModal .modal-content {
  border-radius: 0px !important;
}

.helpVideoModal .modal-body {
  padding: 0px;
}