.site-foot {
  padding: 10px 0 5px 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.3rem;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: rgba(000, 0, 0, 0.5);
  z-index: 9999;
}

.site-foot-nav {
  /* display: flex; */
  align-items: center;
  justify-content: center !important;
}

/* .helpBtn {
  background-color: black !important;
  border-radius: 0px !important;
  border: none !important;
} */

.helpMenu {
  background-color: #15171a !important;

  border-radius: 0px;
}

.helpMenu > a {
  color: white !important;
  font-size: 12px;
}

.helpMenu > a:hover {
  color: #15171a !important;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.pagination li {
  padding-right: 10px;
}

.page-item a {
  font-size: 18px;
  border-radius: 0;
}

.pagination a {
  display: inline-block;
  padding: 0 10px !important;
  text-decoration: none;
  background-color: #000 !important;
  color: #fff;
  border: none;
}
