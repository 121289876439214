.form-div {
  padding-left: 31%;
  padding-right: 31%;
}

form {
  font-size: 15px !important;
}

.page-title {
  text-align: center;
  margin-bottom: 35px;
}

/* .btn-color {
  background: #15171a;
  line-height: 0.428571 !important;
  border-radius: 0px !important;
  border: none;
}

.btn-color:hover {
  background: #15171a !important;
} */

.fpwd-btn {
  line-height: 0.428571 !important;
  margin-top: 10px;
}

.forgot-password {
  font-size: 12px;
  margin-top: 10px;
}

input {
  font-size: 15px !important;
}

/* .btn-color:disabled {
  background: #15171a;
} */

.message {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
}

.margin-right-20 {
  margin-right: 20px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
  .customFormDiv {
    padding-left: 8%;
    padding-right: 8%;
  }
}

/* .guideBtn {
  background-color: black;
  border-radius: 0px;
  text-decoration: none !important;
  border: none;
}

.guideBtn:hover {
  background-color: black !important;
} */

.form-control {
  border-radius: 0px;
}

.tnc {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tnc a {
  text-decoration: none;
}
