.post-feature-image img {
  margin: 0 0 3vw;
  width: 100%;
  height: 500px;
  -o-object-fit: cover;
  object-fit: cover;
}

.post-full-content {
  background: #fff;
  width: 70%;
  margin-left: 3%;
}

.content-title {
  margin: 0 0 0.8em;
  font-size: 5rem;
}

.content-body {
  display: flex;
  flex-direction: column;
}

img {
  max-width: 100%;
}

.accessCard {
  text-align: center;
  width: 50%;
  margin: 0 auto;
  background-color: #15171a !important;
  border-radius: 0px !important;
  color: white !important;
}

.accessCardBody h2 {
  margin-top: 0px;
}

.loadwrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loadText {
  color: black;
  display: inline-block;
  margin-left: 5px;
  font-size: 20px;
}

.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 15px;
  color: black;
}

.bounceball::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: black;
  transform-origin: 50%;
  animation: bounce 500ms alternate infinite ease;
}

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

/* .switchBtn {
  text-align: center;
} */

.switch.btn {
  min-height: 30px !important;
  min-width: 80px !important;
  text-align: center !important;
}

.switch-handle {
  display: none !important;
}

.switch {
  position: fixed !important;
  bottom: 15px !important;
  right: 60px !important;
  z-index: 99999;
  border: unset !important;
  border-radius: 0px !important;
}

.switch-on,
.switch-off {
  font-size: 12px !important;
  font-family: sans-serif !important;
  background-color: black !important;
}

.switch-off.btn {
  padding-left: 0.5em !important;
}

/*Accordion styles*/

/* .accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  background-color: #15171a;
  color: white;
} */

.accordion-item:nth-child(odd) .accordion-button {
  background-color: #d3d3d3;
  color: white;
  padding: 10px;
}

.accordion-item:nth-child(even) .accordion-button {
  background: rgba(21, 23, 26, 0.5);
  color: white;
  padding: 10px;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

/* Pre code CSS */

.content-body pre {
  margin: 0.4em 0 1.8em;
  /* font-size: 1.6rem; */
  line-height: 1.4em;
  white-space: pre-wrap;
  padding: 20px;
  background: #15171a;
  color: chartreuse;
  border-radius: 12px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  position: relative;
}

.copyCodeButton {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 5px;
}

.bookTooltip {
  position: relative;
  display: inline-block;
}

.bookTooltip .bookTooltipText {
  visibility: hidden;
  background-color: black;
  opacity: 0.7;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.bookTooltip:hover .bookTooltipText {
  visibility: visible;
}

.effectLinks {
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: white;
  background: rgba(21, 23, 26, 0.5);
  padding: 10px;
}

.effectLinks::after {
  content: "";
  position: absolute;
  left: 0;
  padding-left: 10px;
}

.effectLinks:hover {
  background: rgba(21, 23, 26, 1);
  color: white;
}

.effectLinks::after {
  content: attr(data-replace);
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  color: white;
  background: rgba(21, 23, 26, 1);
}

.effectLinks:hover::after {
  transform: translate3d(0, 0, 0);
}

.effectLinks span {
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.effectLinks:hover span {
  transform: translate3d(-200%, 0, 0);
}
