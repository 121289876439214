@import "bootstrap/dist/css/bootstrap.min.css";

/* * {
  scroll-margin-top: 80px;
} */

.form-group {
  margin-top: 20px !important;
}

.header {
  color: #fff;
  position: fixed !important;
  width: 100%;
  top: 0;
  box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
  z-index: 99999;
  background-color: rgba(000, 0, 0, 0.5) !important;
}

.loginBtn {
  text-decoration: none;
  background: #15171a !important;
  padding: 5px 10px;
  color: #fff !important;
  font-size: 12px;
}

.mainPage {
  padding: 80px 0;
}

.chapterListBtn > a {
  color: white !important;
  background-color: #15171a !important;
  font-size: 12px;
  padding: 5px 10px;
}

.chapterListBtn > div {
  background-color: #15171a !important;
  border-radius: 0px !important;
}

.chapterListBtn > div > a {
  color: white !important;
  font-size: 12px;
  padding: 3px 5px;
}

.chapterListBtn > div > a:hover {
  color: #15171a !important;
}

.search-container-main {
  margin: 4px 2px;
  height: 30px;
  width: 50px;
  bottom: 0px;
}

.mglass-main {
  display: inline-block;
  pointer-events: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

.searchbutton-main {
  font-size: 22px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.search-main:focus + .searchbutton-main {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  display: none;
  background-color: black;
}

.search-main {
  position: absolute;
  outline: none;
  border: none;
  padding: 0;
  width: 0;
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: black;
  font-size: 12px !important;
  height: 25px !important;
  color: white;
}

.search-main::placeholder {
  color: white;
}

.search-main:focus {
  width: 150px;
  padding: 0 2px 0 2px;
  border: 1px black solid !important;
}

.button-main {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.button-main:hover {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  color: black;
}

.searchFilter {
  position: absolute;
  background: black;
  color: white;
  padding: 1em;
  max-height: 80vh;
  width: 80vw;
  max-width: 30em;
}

.searchFilter a {
  text-decoration: none;
  color: white;
  font-weight: 500;
  line-height: 1.2;
  margin: 1.5em 0 0.5em;
  font-size: 1.3rem;
  margin-bottom: 0.2em;
}

.searchFilter div {
  margin-bottom: 0.2em;
}

.searchFilter a:hover {
  text-decoration: none;
  background-color: white;
  color: black;
}

.filterHide {
  display: none;
}

.commonBtn {
  background-color: #15171a !important;
  border-radius: 0px !important;
  font-size: 12px !important;
  padding: 5px 10px !important;
}

.marginTop_five {
  margin-top: 5px !important;
}

iframe {
  width: 100%;
  height: 300px;
}

.dropdown-menu {
  min-width: 100% !important;
}
