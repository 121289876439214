.toc {
  width: 250px;
  min-width: 220px;
  /* max-height: 200px; */
  /* padding: 16px; */
  align-self: flex-start;
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
  max-height: calc(100vh - 130px);
  overflow: auto;
  margin-top: 0px;
  background: #15171a;
  color: white;
}

.headTOC {
  color: white;
  font-size: 22px;
  text-align: center;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 14px !important;
}

.head3 {
  margin-left: 10px;
  list-style-type: circle;
  color: white;
}
.head4 {
  margin-left: 20px;
  list-style-type: square;
  color: white;
}

h2,h3,h4{
  scroll-margin-top: 70px;
}