.chapterLink {
  text-decoration: none;
}
.chapterCard {
  border: none;
}

.chapterImg {
  border-radius: 0px;
  height: 250px;
}

.chapterCardBody {
  padding-left: 0px;
}

.post-card-tags {
  margin: 0 0 5px;
  /* font-size: 1.4rem; */
  line-height: 1.15em;
  color: #5b7a81;
}
.post-card-title {
  margin: 0 0 10px;
  padding: 0;
  font-size: 1.5em;
  color: #15171a;
}
.post-card-excerpt {
  line-height: 1.55em;
  margin-bottom: 8px;
  color: #15171a;
}
.post-card-footer-left {
  display: flex;
  align-items: center;
}

.post-card-avatar {
  width: 30px;
  height: 30px;
  margin: 0 7px 0 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-card-avatar .author-profile-image {
  display: block;
  width: 100%;
  background: var(--color-secondary);
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.post-card-footer-left span {
  font-size: 12px;
  color: #5b7a81;
}

.post-card-image {
  margin: 0 0 10px;
  width: auto;
  height: 200px;
  background: #5b7a81 no-repeat 50%;
  background-size: cover;
}